<template>
	<div class="login">
		<div class="header"></div>
		<div class="body">
			<a-card class="login-card">
				<div class="logo">
					<img src="@/assets/logo-white.png" alt="" width="350" height="140" />
				</div>
				<!-- <h2>用户登录</h2> -->
				<a-form-model class="login-form-model">
					<a-form-model-item>
						<a-input v-model="usernameInput" ref="usernameInput" size="large" placeholder="请填写手机号"
							allowClear @focus="
                (event) => {
                  event.currentTarget.select();
                }
              " @pressEnter="$refs.passwordInput.focus()">
							<a-icon slot="prefix" type="user" />
						</a-input>
					</a-form-model-item>
					<a-form-model-item>
						<a-input-password v-model="passwordInput" ref="passwordInput" size="large" placeholder="请填写密码"
							allowClear @focus="
                (event) => {
                  event.currentTarget.select();
                }
              " @pressEnter="handleLoginBtnClick">
							<a-icon slot="prefix" type="key" />
						</a-input-password>
					</a-form-model-item>
					<a-form-model-item>
						<a-button type="primary" size="large" block @click="handleLoginBtnClick">
							登录
						</a-button>
					</a-form-model-item>
				</a-form-model>
			</a-card>
		</div>
		<div class="footer">
			<div class="copyright">
				<p>
					<div>{{appVersionTypeCN}} {{appVersionNumber}}</div>
					<a href="https://beian.miit.gov.cn" title="备案号" target="_blank">闽ICP备13018032号-2</a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import CryptoJS from "crypto-js";
	import UmsUserApi from "@/api/ums/ums-user.js";

	export default {
		name: "Login",
		data() {
			return {
				appVersionTypeCN: process.env.VUE_APP_VERSION_TYPE_CN,
				appVersionNumber: process.env.VUE_APP_VERSION_NUMBER,
				usernameInput: "",
				passwordInput: "",
			};
		},
		mounted: function() {
			this.usernameInput = this.$store.state.account.username;
		},
		methods: {
			handleLoginBtnClick: function() {
				if (!this.usernameInput || !this.passwordInput) {
					this.$message.warning("请正确填写用户名和密码");
					return;
				}

				UmsUserApi.login(
					this.usernameInput,
					CryptoJS.MD5(this.passwordInput).toString()
				).then((response) => {
					if (response.status === "SUCCESS") {
						this.$store.commit("setAccount", response.body);
						this.$message.success("登录成功！");
						this.$router.push({
							name: "Homepage",
						});
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.login {
		width: 100%;
		height: 100%;
		background-image: url("../../assets/login.jpeg");
		-moz-background-size: 100% 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;

		.body {
			.login-card {
				position: absolute;
				top: 0;
				left: 55%;
				margin-top: 60px;
				margin-left: -200px;
				width: 400px;
				min-height: 300px;
				text-align: center;
				background-color: transparent;
				border: 0;
				filter: alpha(Opacity=80);
				-moz-opacity: 0.8;
				opacity: 0.8;

				.login-form-model {
					margin-top: 2em;
				}

				.login-form-model .ant-form-item {
					margin-bottom: 12px;
				}
			}
		}

		.footer {
			position: fixed;
			bottom: 10px;
			left: 0;
			width: 100%;
			text-align: center;
			z-index: 999999;

			.copyright {
				a {
					color: #434343;
					text-decoration: none;
				}

				a:hover {
					color: #15a1e2;
					text-decoration: underline;
				}
			}
		}
	}
</style>
